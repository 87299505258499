import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="section is-main">
          <h1 className="title">Four oh Four (404)</h1>
          <p className="subtitle">The page you are looking for doesn't exist</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
